// A stepper that is displayed in the hero template over the hero blue colour and
// doesn't change based on color mode.
.stepper.stepper-pills.stepper-column.stepper-hero {
    .stepper-line {
        border-left-color: #{$gray-300};
    }
    .stepper-icon {
        background-color: #{$primary-light};
    }
    .stepper-number {
        color: #{$primary};
    }
    .stepper-title,
    .stepper-desc {
        color: #{$white};
    }
    .stepper-item.current {
        .stepper-number,
        .stepper-title,
        .stepper-desc {
            color: #{$white};
        }
        .stepper-icon {
            background-color: #{$primary};
        }
    }
}
