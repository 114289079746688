/*
 * Separator for Bootstrap pagination.
 *
 * The page-item-separator class is for <a> elements within a container elements used for Pagination.
 * It adds an ellipsis before any page(s) that are not immediately preceded by the previous page due to length constraints.
 *
 * <nav aria-label="Pagination">
 *   <ul class="pagination">
 *     <li class="page-item"><a href="" class="page-link" aria-label="Page 1" aria-current="page">1</a></li>
 *     <li class="page-item"><a href="?page=3" class="page-link page-item-separator" aria-label="Page 3">3</a></li>
 *     <li class="page-item"><a href="?page=4" class="page-link" aria-label="Page 4">4</a></li>
 *     <li class="page-item active"><a href="?page=5" class="page-link" aria-label="Page 5">5</a></li>
 *   </ul>
 * </nav>
 */

.pagination {
    a.page-item-separator {
        &::before {
            content: "…";
            padding-right: 1.25rem;
        }

        margin-left: 0;
        padding-left: 0;
    }
}
