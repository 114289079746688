//
// Adapt Metronic Select2 CSS for Select2 4.0.13
//
// Metronic includes a theme for Select2 4.1.0-rc0, which uses different HTML markup to
// the 4.0.13 which is packaged with Django and Django Autocomplete Light. This CSS
// smoothes over the differences so that Metronic theme works for 4.0.13.
//

.select2-container {
    width: 100%;

    &.select2-container--bootstrap5 {
        .select2-selection--multiple {
            &:not(.form-select-sm):not(.form-select-lg)
                .select2-selection__choice {
                padding-left: 1.6rem;
            }

            .select2-selection__rendered {
                overflow: visible;
                overflow-wrap: break-word;
                text-overflow: clip;
                white-space: normal;
                width: 100%;

                .select2-selection__choice {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    background-color: $gray-300;

                    .select2-selection__choice__remove {
                        left: 0.6rem;
                    }
                }
            }

            .select2-search.select2-search--inline {
                width: 100%;
            }
        }

        .select2-search.select2-search--inline .select2-search__field {
            width: 100% !important;
        }

        .select2-search.select2-search--inline
            .select2-search__field::placeholder {
            width: 100%;
        }
    }

    .select2-search--inline {
        float: none;
    }

    .select2-selection--single {
        .select2-selection__clear {
            position: absolute;
        }
    }
}
