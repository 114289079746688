// Allow inline SVG to be used in .symbol containers.

.symbol {
    > svg {
        width: 100%;
        flex-shrink: 0;
        display: inline-block;
        @include border-radius($border-radius);
    }

    > svg {
        width: 50px;
        height: 50px;
    }

    // Sizes
    @include symbol-size(get($symbol-sizes, default));

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $name, $value in $symbol-sizes {
                @if ($name != "default") {
                    &.symbol#{$infix}-#{$name} {
                        > svg {
                            width: $value;
                            height: $value;
                        }
                    }
                }
            }
        }
    }
}
