// Muted text colour for over secondary bg colour
.text-muted-secondary {
    color: var(--mc-text-muted-secondary);
}

// Alternative font for headings.
h1,
h2,
.h1,
.h2 {
    font-family: Titillium Web, $font-family-sans-serif;
}
