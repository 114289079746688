/**
 * Additional classes for Bootstrap Collapse components.
 *
 * https://getbootstrap.com/docs/5.2/components/collapse/
 */

/*
 * A link for controlling Collapse components.
 *
 * <a class="collapse-link" href="#id_collapse" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="id_collapse">
 *   <span class="collapse-link-icon svg-icon svg-icon-2 svg-icon-muted">
 *     <svg>...</svg>
 *   </span>
 *   <span>Collapse Link</span>
 * </a>
 * <div class="collapse" id="id_collapse">...</div>
 *
 */

.collapse-link {
    display: flex;
    margin-bottom: map-get($spacers, 3);

    .collapse-link-icon {
        transition: $menu-accordion-arrow-transition;
    }

    &[aria-expanded="true"] .collapse-link-icon {
        transform: rotate(90deg);
    }
}
