// Set menu link focus state. Duplicated from components/mixins/_menu.scss and modified.
@mixin menu-link-focus-state(
    $title-color,
    $icon-color,
    $bullet-color,
    $arrow-color,
    $bg-color: null,
    $all-links: true
) {
    $sel: "&:not(.here) ";

    @if ($all-links == false) {
        $sel: "&:not(.here) > ";
    }

    &.focus:not(.here) > .menu-link:not(.disabled):not(.active):not(.here),
    #{$sel}.menu-link:focus:not(.disabled):not(.active):not(.here) {
        transition: $transition-link;

        @include menu-link-theme(
            $title-color,
            $icon-color,
            $bullet-color,
            $arrow-color,
            $bg-color
        );
    }
}

// Ensure <button> elements being used as menu-links has consistent styles
// as other items in the Mancini user menu.
button.menu-link {
    width: 100%;
    font-weight: 500 !important;
    // Reset button styles
    background: none;
    border: none;
}
