/*
 * A numbered task list that shows completed items.
 *
 * <ol class="list-tasklist">
 *   <li class="list-tasklist-item list-tasklist-item-complete"><del>Do thing 1</del></li>
 *   <li class="list-tasklist-item">Do thing 2>/li>
 *   <li class="list-tasklist-item">Do thing 3</li>
 * </ol>
 */
.list-tasklist {
    list-style: none;
    counter-reset: tasklist-counter;
    margin: 0;
    padding: 0;

    .list-tasklist-item {
        position: relative;
        min-height: 3rem;
        padding-top: 0.3rem;
        padding-left: 3.2em;
        counter-increment: tasklist-counter;
    }

    .list-tasklist-item::before {
        content: counter(tasklist-counter);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        min-width: 2.4rem;
        margin-right: 0.75rem;
        border: 1px solid var(--bs-card-border-color);
        @include border-radius($border-radius);
        padding: 0.1rem;
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
        background-color: var(--bs-dark-inverse);
    }

    .list-tasklist-item-complete::before {
        content: "\2713";
        color: var(--bs-success);
        background-color: var(--bs-light);
    }
}
