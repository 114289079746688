//
// Scroll bar overrides and customisation.
//

@include media-breakpoint-up(lg) {
    main,
    span,
    ol,
    ul,
    pre,
    div {
        // Make scrollbars on overflowing elements more visible in Firefox
        scrollbar-width: auto;
    }
}
