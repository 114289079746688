@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto-Italic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: auto;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: Titillium Web;
    src: url("../fonts/TitilliumWeb-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: Titillium Web;
    src: url("../fonts/TitilliumWeb-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: Titillium Web;
    src: url("../fonts/TitilliumWeb-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: Titillium Web;
    src: url("../fonts/TitilliumWeb-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: Titillium Web;
    src: url("../fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: Titillium Web;
    src: url("../fonts/TitilliumWeb-SemiBoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: auto;
}
