//
// Set custom variables and override Bootstrap and Metronic SASS variables.
//
// Note that this file is included first and variables defined in
// components/_variables.bootstrap.scss are not accessible in this file.
//

// Declare early for use in subsequent overriden variables, no colour change
$prefix: bs-;
$white: #fff;
$black: #000;
$gray-100: #f9f9f9;
$gray-200: #f4f4f4;
$gray-300: #e4e6ef;
$gray-400: #b5b5c3;
$gray-500: #a1a5b7;
$gray-600: #7e8299;
$gray-700: #5e6278;
$gray-800: #3f4254;
$gray-900: #181c32;
$gray-100-dark: #1b1b29;
$gray-200-dark: #2b2b40;
$gray-300-dark: #323248;
$gray-400-dark: #474761;
$gray-500-dark: #565674;
$gray-600-dark: #6d6d80;
$gray-700-dark: #92929f;
$gray-800-dark: #cdcdde;
$gray-900-dark: #fff;
$body-bg: #fff;

//
// Theme colors. Adapted from nci.org.au palette with adaptations for contrast.
//

// Primary
$primary: #0c69af;
$primary-active: #094d81;
$primary-light: #e4f0fc;
$primary-clarity: rgba($primary, 0.2);
$primary-dark: $primary;
$primary-active-dark: $primary-active;
$primary-light-dark: #001e32;
$primary-clarity-dark: rgba($primary-dark, 0.2);
$primary-inverse: #fff;
$primary-inverse-dark: $primary-inverse;

// Secondary colors
$secondary: $gray-700;
$secondary-active: $gray-800;
$secondary-light: $gray-100;
$secondary-clarity: rgba($secondary, 0.2);
$secondary-inverse: #fff;
$secondary-dark: $gray-300-dark;
$secondary-active-dark: $gray-400-dark;
$secondary-light-dark: $gray-100-dark;
$secondary-clarity-dark: rgba($secondary-dark, 0.2);
$secondary-inverse-dark: $gray-700-dark;

// Light
$light: $gray-100;
$light-active: $gray-300;
$light-light: rgba($gray-100, 0.75);
$light-clarity: rgba($white, 0.2);
$light-inverse: #62667d;
$light-dark: $gray-100-dark;
$light-active-dark: $gray-200-dark;
$light-light-dark: $light-light;
$light-clarity-dark: rgba(31, 33, 42, 0.2);
$light-inverse-dark: #9093a7;

// Success
$success: #1e7b34;
$success-active: #145122;
$success-light: #d5f5d5;
$success-clarity: rgba($success, 0.2);
$success-dark: $success;
$success-active-dark: $success-active;
$success-light-dark: #0a2811;
$success-clarity-dark: rgba($success-dark, 0.2);
$success-inverse: #fff;
$success-inverse-dark: $success-inverse;

// Info
$info: #097775;
$info-active: #075e5b;
$info-light: #d6f4f4;
$info-clarity: rgba($info, 0.2);
$info-dark: $info;
$info-active-dark: $info-active;
$info-light-dark: #032f2e;
$info-clarity-dark: rgba($info-dark, 0.2);
$info-inverse: #fff;
$info-inverse-dark: $info-inverse;

// Danger
$danger: #d42537;
$danger-active: #b02a37;
$danger-light: #fceeee;
$danger-clarity: rgba($danger, 0.2);
$danger-dark: $danger;
$danger-active-dark: $danger-active;
$danger-light-dark: #3a2434;
$danger-clarity-dark: rgba($danger-dark, 0.2);
$danger-inverse: #fff;
$danger-inverse-dark: $danger-inverse;

// Warning
$warning: #aa4a17;
$warning-active: #863912;
$warning-light: #fae4d6;
$warning-clarity: rgba($warning, 0.2);
$warning-dark: $warning;
$warning-active-dark: $warning-active;
$warning-light-dark: #431c09;
$warning-clarity-dark: rgba($warning-dark, 0.2);
$warning-inverse: #fff;
$warning-inverse-dark: $warning-inverse;

// Dark
$dark: $gray-900;
$dark-active: darken($gray-900, 3%);
$dark-light: $gray-200;
$dark-clarity: rgba($dark, 0.2);
$dark-inverse: $white;
$dark-dark: $gray-900-dark;
$dark-active-dark: lighten($gray-900-dark, 3%);
$dark-light-dark: $gray-200-dark;
$dark-clarity-dark: rgba($dark-dark, 0.2);
$dark-inverse-dark: $gray-100-dark;

// Extra colours from the NCI Web Guidlines v1a
$nci-highlight-orange: #ffa846;
$nci-hero-blue: #324159;
$nci-highlight-blue: #57b0f2;
$nci-highlight-green: #369372;
$nci-highlight-purple: #574ae2;
$orcid-green: #a6ce39;

$body-bg: #fff;
$body-bg-dark: #151521;

// Card
$card-box-shadow: none;
$card-border-enabled: true;

// Use browser font size as base font size (defaults to 16px)
$root-font-size: 1rem;
$root-font-size-lg: $root-font-size; // tablet
$root-font-size-md: $root-font-size; // mobile
$font-family-sans-serif: Roboto, Helvetica, "sans-serif";

// Higher contrast muted text colours.
$text-muted: #6f7590;
$text-muted-dark: $gray-700-dark;

// Muted text colour for over the secondary bg colour.
$text-muted-secondary: #6a718a;
$text-muted-secondary-dark: #cfcfcf;

// Higher contrast menu colour.
$component-hover-color: #0d72bf;
$component-hover-color-dark: $gray-800-dark;

// Tables caption color
$table-caption-color: var(--bs-body-color);

// Form colours
$form-label-color: var(--#{$prefix}gray-800);
$form-check-label-color: var(--#{$prefix}gray-800);

// Style anchor elements.
$link-color: #0e78c8;
$link-hover-color: #094d81;
$link-color-dark: #0f83db;
$link-hover-color-dark: #4db2ff;
$link-hover-decoration: underline;

// Higher contrast tab colours and matching borders.
$nav-tabs-border-color: $gray-400;
$nav-tabs-link-active-bg: var(--#{$prefix}body-bg);
$nav-tabs-link-active-border-color: $gray-400 $gray-400 $nav-tabs-link-active-bg;
$nav-tabs-link-hover-border-color: $gray-300 $gray-300 $nav-tabs-border-color;

// Slimmer buttons. Using Bootstrap defaults.
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;

// Breadcrumbs
$breadcrumb-active-color: var(--#{$prefix}gray-700);

$scrolltop-bg-color-dark: $gray-700-dark;

// Colour map for --mc-*-light-dark CSS vars.
$light-dark-colors: (
    "primary": $primary-light-dark,
    "secondary": $secondary-light-dark,
    "success": $success-light-dark,
    "info": $info-light-dark,
    "danger": $danger-light-dark,
    "warning": $warning-light-dark,
);

// Alert border color adjustments.
$alert-color-scale: 80%;
$alert-color-scale-dark: 40%;

$input-border-color: $gray-600;
$border-color: $gray-300;

// Aside
$aside-config: (
    z-index: 101,
    width: 208px,
);

// Header
$header-config: (
    z-index: 102,
    height: (
        desktop: 94px,
        tablet-and-mobile: 70px,
    ),
    transition-speed: 0.3s,
    bg-color: $nci-hero-blue,
    bg-color-dark: $nci-hero-blue,
    border-color: #e4e6ef,
    border-color-dark: #1e1e2d,
);

// Scrollbars
$scrollbar-color-dark: $gray-700-dark;
$scrollbar-hover-color-dark: $gray-600-dark;
$scrollbar-color: $gray-400;
$scrollbar-hover-color: $gray-500;

// Accordions
$accordion-button-active-color-dark: #fff;
$accordion-button-focus-border-color: $nci-highlight-blue;
$accordion-button-focus-border-color-dark: $nci-highlight-blue;
$accordion-button-focus-box-shadow: 0 0 0 0.25rem #{$nci-highlight-blue};
$accordion-button-focus-box-shadow-dark: 0 0 0 0.25rem #{$nci-highlight-blue};

// SVG Icons
$custom-icon-colours: (
    "hero-blue": $nci-hero-blue,
    "highlight-orange": $nci-highlight-orange,
    "highlight-blue": $nci-highlight-blue,
    "highlight-green": $nci-highlight-green,
    "highlight-purple": $nci-highlight-purple,
    "orcid-green": $orcid-green,
);

// Code
$code-color: $nci-highlight-purple;
$code-color-dark: #9088ec;

// Override to set $light-inverse-dark
$theme-inverse-colors-dark: (
    "primary": $primary-inverse,
    "secondary": $secondary-inverse-dark,
    "light": $light-inverse-dark,
    "success": $success-inverse,
    "info": $info-inverse,
    "warning": $warning-inverse,
    "danger": $danger-inverse,
    "dark": $dark-inverse-dark,
);
