//
// Custom header button for user account menu.
//

.btn-useraccount {
    color: var(--bs-gray-100);
    background-color: var(--bs-header-bg-color);
    border: 0;
    border-radius: $border-radius;

    &:hover,
    &:focus-visible,
    &:active {
        background-color: #405877;

        .text-muted {
            color: var(--bs-gray-100) !important;
        }
    }
}

@include color-mode(dark) {
    .btn-useraccount {
        color: var(--bs-gray-800);
    }
}

@each $name, $value in $theme-colors {
    // Override .btn-outline-* classes to give sufficient constrast for hover/active in
    // dark mode. Derived from components/buttons/_theme.scss.
    .btn.btn-outline.btn-outline-#{$name} {
        $color: var(--bs-#{$name});
        $icon-color: var(--bs-#{$name});
        $border-color: var(--bs-#{$name});
        $bg-color: transparent;

        $color-active: var(--bs-#{$name}-inverse);
        $icon-color-active: var(--bs-#{$name}-active);
        $border-color-active: var(--bs-#{$name});
        $bg-color-active: var(--bs-#{$name});

        @include button-custom-variant(
            $color,
            $icon-color,
            $border-color,
            $bg-color,
            $color-active,
            $icon-color-active,
            $border-color-active,
            $bg-color-active
        );
    }

    // Override .btn-light-* FG colour to give sufficient constrast in dark
    // mode. Derived from components/buttons/_theme.scss.
    @if (theme-light-color($name)) {
        @include color-mode(dark) {
            .btn.btn-light-#{$name} {
                $color: var(--bs-gray-800);
                $icon-color: var(--bs-#{$name});
                $border-color: var(--bs-#{$name}-light);
                $bg-color: var(--bs-#{$name}-light);

                $color-active: var(--bs-#{$name}-inverse);
                $icon-color-active: var(--bs-#{$name}-inverse);
                $border-color-active: var(--bs-#{$name});
                $bg-color-active: var(--bs-#{$name});

                @include button-custom-variant(
                    $color,
                    $icon-color,
                    $border-color,
                    $bg-color,
                    $color-active,
                    $icon-color-active,
                    $border-color-active,
                    $bg-color-active
                );
            }
        }
    }
}

// Use a higher constrast icon hover colour, the Metronic standard is dark on dark.
.btn.btn-outline.btn-outline-secondary:hover:not(.btn-active) .svg-icon {
    color: var(--bs-secondary-inverse);
}

// Standard Metronic formula doesn't work at all for secondary colours in dark mode so
// user a darker fg colour.
@include color-mode(dark) {
    .btn.btn-outline.btn-outline-secondary {
        color: $gray-800-dark;
    }
}

//
// Outline button with ORCID colouring
//
.btn.btn-outline.btn-outline-orcid {
    @include button-custom-variant(
        var(--bs-gray-800),
        $orcid-green,
        var(--bs-gray-300),
        transparent,
        var(--bs-gray-800),
        $orcid-green,
        $orcid-green,
        transparent
    );
}

//
// Override the custom setting for .btn-dark in Metronic
//      frontend/src/metronic/sass/components/buttons/_theme.scss
// Follows `$dark-<type>` variables as in
//      frontend/src/sass/custom/_variables.scss
//
@include color-mode(dark) {
    .btn.btn-dark {
        @include button-custom-variant(
            $color: $dark-inverse-dark,
            $icon-color: null,
            $border-color: null,
            $bg-color: $dark-dark,
            $color-active: $dark-inverse-dark,
            $icon-color-active: null,
            $border-color-active: null,
            $bg-color-active: $dark-active-dark
        );
    }
}

// Override the Metronic config for color of .btn-light-secondary
@include color-mode(light) {
    .btn.btn-light-secondary {
        @include button-custom-variant(
            var(--#{$prefix}secondary),
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );
    }
}

//
// Override btn styles for the Theme switchers in the User menu
//
.theme-btn {
    margin-left: 0.5px !important;
    margin-right: 0.5px !important;
    box-sizing: border-box;

    // The currently applied theme
    &:has(> input:checked) {
        background-color: var(--bs-primary);
        color: var(--bs-primary-inverse);
    }

    // Focused by keyboard (arrow keys) or mouse hover
    &:focus-within,
    &:hover {
        outline: var(--bs-gray-300) solid 3px !important;

        // Alter the icon color
        .sun-and-moon {
            color: var(--mc-highlight-orange);
        }

        svg,
        svg path {
            fill: var(--mc-highlight-orange);
        }
    }

    // Highlight when a non-current theme is hovered over
    &:hover:not(:has(> input:checked)) {
        background-color: var(--bs-menu-link-bg-color-hover) !important;
        color: var(--bs-menu-link-color-hover) !important;
        -webkit-transition: color 0.2s ease;
        transition: color 0.2s ease;
    }
}
