// Focus indicator for menu links.
.menu-state-bg {
    .menu-item {
        @include menu-link-focus-state(
            var(--bs-menu-link-color-hover),
            var(--bs-menu-link-color-hover),
            var(--bs-menu-link-color-hover),
            var(--bs-menu-link-color-hover),
            var(--bs-menu-link-bg-color-hover)
        );
    }
}

// Higher contrast colours for line tabs.
.nav-line-tabs {
    .nav-item {
        .nav-link {
            color: var(--bs-nav-link-color);
            &.active {
                color: #{$nav-tabs-link-active-color};
            }
        }
    }
}

.aside .menu .menu-item > .menu-link.active {
    border-left-color: var(--mc-highlight-blue);
}
