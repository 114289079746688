@import "fonts";
@import "buttons";
@import "lists";
@import "menus";
@import "text";
@import "type";
@import "sizing";
@import "select2";
@import "stepper";
@import "symbols";
@import "svg-icon";
@import "alert";
@import "layout";
@import "collapse";
@import "pagination";
@import "editor_user_content";
@import "htmx";
@import "scroll";

// New custom CSS vars, with --mc (mancini) prefix.
@include color-mode(light) {
    --mc-text-muted-secondary: #{$text-muted-secondary};
    --mc-hero-blue: #{$nci-hero-blue};
    --mc-highlight-blue: #{$nci-highlight-blue};
    --mc-highlight-orange: #{$nci-highlight-orange};
    --mc-highlight-green: #{$nci-highlight-green};
    --mc-highlight-purple: #{$nci-highlight-purple};
    --mc-orcid-green: #{$orcid-green};
}

@include color-mode(dark) {
    --mc-text-muted-secondary: #{$text-muted-secondary-dark};
    --mc-highlight-orange: #{$nci-highlight-orange};

    // Create CSS variables for the dark-mode light theme colours.
    @each $name, $value in $light-dark-colors {
        --mc-#{$name}-light-dark: #{$value};
    }
}
