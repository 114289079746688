/*
 * Styles user content within rich-text editors by `.medit-` classes.
 */

.medit-underline {
    text-decoration: underline;
}

.medit-left {
    text-align: left;
}

.medit-center {
    text-align: center;
}

.medit-right {
    text-align: right;
}

.medit-justify {
    text-align: justify;
}
