/**
 * Styles for various elements of layout.
 */

/*
 * The coloured panel of large format "hero" pages.
 */

.hero-aside {
    background-color: #{$nci-hero-blue};
}
