//
// SVG Icon
//

.svg-icon {
    // Additional colors
    @each $name, $color in $custom-icon-colours {
        &.svg-icon-#{$name} {
            color: var(--mc-#{$name});
        }
    }
}
