// Styles for creating an element in content whose position is adjusted so that when
// it's scrolled into view (eg, following an htmx request) the actual content is not
// hidden behind the header.
//
// Place the following immediately before the content you wish to scroll to.
//
// <div class="position-header-adjust">
//    <div id="target" class="position-header-adjust-target"></div>
// </div>

.position-header-adjust {
    position: relative;
}
.position-header-adjust-target {
    position: absolute;
    top: -93px;
}

// New class .htmx-indicator-hidden the can be combined with .htmx-indicator so the
// element is removed from display until it should be shown, instead of the default
// behaviour, which is to set the opacity to zero.
.htmx-indicator.htmx-indicator-hidden {
    display: none;
}

.htmx-request .htmx-indicator.htmx-indicator-hidden {
    display: inline-block;
}
