//
// Overrides for Metronics alerts.
//

// Use more subtle borders.
@each $name, $value in $theme-colors {
    .alert-#{$name} {
        border-color: tint-color($value, $alert-color-scale);
    }
}

// Use an accessible, high contrast fg colour for dark-mode alerts and subtler borders.
@each $name, $value in $theme-colors {
    @include color-mode(dark) {
        .alert-#{$name} {
            color: var(--bs-gray-800-dark);
            border-color: shift-color($value, $alert-color-scale-dark);

            .alert-link {
                color: var(--bs-gray-800-dark);
                text-decoration: underline;
            }
        }
    }
}

// Ensure links in alerts don't have horrible colour clashes but still look linky.
.alert a {
    color: inherit;
    text-decoration: underline;
}
